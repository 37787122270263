export const commonTranslationKeys = {
  commonYes: 'common.yes',
  commonNo: 'common.no',
  commonOkayGotIt: 'common.okay-got-it',

  commonDelete: 'common.delete',
  commonDeleted: 'common.deleted',
  commonCancel: 'common.cancel',
  commonConfirm: 'common.confirm',
  commonDone: 'common.done',
  commonEdit: 'common.edit',
  commonSave: 'common.save',
  commonProceed: 'common.proceed',
  commonGoBack: 'common.go-back',
  commonAnd: 'common.and',
  commonLeave: 'common.leave',
  commonTryAgain: 'common.try-again',
  commonAbout: 'common.about',

  commonSomethingWentWrong: 'common.something-went-wrong',
  commonSomethingWrongTryAgain: 'common.something-wrong-try-again',
  commonErrorDefaultMessage: 'common.error-default-message',
  commonErrorDefaultDescription: 'common.error-default-description',
  commonNoData: 'common.no-data',
  tableError: 'statistics.table-error',
  commonNA: 'common.na',
  commonSuccess: 'common.success',
  commonNotConfirmed: 'common.not-confirmed',
  commonConfirmed: 'common.confirmed',
  commonClose: 'common.close',

  commonChangesSaved: 'common.changes-saved',
  commonSaveChanges: 'common.save-changes',

  commonShowAll: 'common.show-all',
  commonHide: 'common.hide',

  commonGoToFaq: 'common.go-to-faq',

  commonNew: 'common.new',

  statisticsFiltersClearAll: 'statistics.filters-clear-all',
  statisticsFiltersApplyFilters: 'statistics.filters-apply-filters',

  commonCountryLabel: 'common.country-label',

  commonDay: 'common.day',
  commonOfTheDay: 'common.of-the-day',
  commonDays: 'common.days',
  commonHour: 'common.hour',
  commonOfTheHour: 'common.of-the-hour',
  commonHours: 'common.hours',
  commonMinute: 'common.minute',
  commonOfTheMinute: 'common.of-the-minute',
  commonMinutes: 'common.minutes',
}
