import { DateTime } from 'luxon'

import { CoreTypesComponents } from '@affiliate-cabinet/types'
import { createEnumObject } from '@affstore-ds/types'

export const CONTACT_LIST_VALUES = createEnumObject<CoreTypesComponents['schemas']['AlternativeContactQueryDto']['type']>({
  none: 'none',
  whatsapp: 'whatsapp',
  skype: 'skype',
  viber: 'viber',
  facebook: 'facebook',
  telegram: 'telegram',
  qq: 'qq',
  wechat: 'wechat',
  weibo: 'weibo',
  email: 'email',
})

export const AFFSTORE_SITE_ID_LIST = [6, 12]

export const notSelectedItem = {
  value: '',
  label: 'select.not-selected',
  'data-testid': 'not-selected',
}

export const DATE_SHOW_FORMAT = 'dd.MM.yyyy'
export const DATE_SHOW_FULL_FORMAT = 'LLLL dd yyyy, HH:mm'
export const DATE_MODEL_FORMAT = 'yyyy-MM-dd'
export const DATE_MODEL_FORMAT_EXTENDED = 'yyyy-MM-DD HH:mm'
export const DATE_ISO_WITHOUT_ZONE = 'yyyy-MM-DDTHH:mm:ss'
export const DATE_HH_MM = 'H:mm'
export const DATE_MESSAGE_FORMAT = 'yyyy-MM-DD, HH:mm'
export const MINUTES_IN_HOUR = 60
export const TABLE_PAGE_SIZES = [10, 25, 50, 100, 300]
export const DOZEN_TABLE_PAGE_SIZES = [12, 24, 48, 96, 300]
export const FORM_SEND_DELAY = 500
export const SHOW_MESSAGE_TIME = 10_000
export const SMS_CODE_EXPIRACTION_TIME = 300_000

export const minDate = DateTime.now().set({ year: 2013, month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate()

export const URL_PLACEHOLDER = 'https://website.com'

export const affTrackSearchRegExp = /[^\w!$()*+,./=@-]+/gi

const SUPPORT_LINK_PATH = 'https://support.affstore.com'

export const getSupportTicketLink = (ticketId: string | number) => `${SUPPORT_LINK_PATH}/requests/${ticketId}`
export const getCreateSupportTicketLink = () => `${SUPPORT_LINK_PATH}/requests/new`

export const QS_ARRAY_LIMIT_VALUE = 10_000 // { arrayLimit: QS_ARRAY_LIMIT_VALUE }

export const NO_MANAGER_ID = 7454

export const MODAL_STATE_QUERY_KEY = 'action'

export const NOT_IDENTIFIED_COUNTRY_CODE = '--'

export const ZERO_VALUE = 0
export const ONE_VALUE = 1

export const RANK10 = 10
